<template>
  <b-card style="">
    <b-card-title>
      <div style="display: flex; justify-content: space-between">
        <div>Error Occured</div>
        <div style="cursor: pointer" @click="$hideBlockUI()">X</div>
      </div>
    </b-card-title>
    <b-card-text>
      <div>An error has occurred. Please contact administrator. <br />When contacting the administrator, please include the error code below, it will be helpful in figuring out the error.</div>
    </b-card-text>
    <b-card-text class="font-size-10px">
      <div class="display-inlineblock" style="width: 100px">Error Code</div>
      <div class="display-inlineblock" style="width: calc(100% - 145px)">
        <b-form-input size="sm" name="error_code" v-model="getBlockUIErrorInfo.error_code" class="font-size-10px" readonly></b-form-input>
      </div>
      <div class="display-inlineblock">
        <b-button size="sm" variant="outline-secondary" @click="copyErrorCode" class="copy-errorcode-button outline-secondary-hover">
          <feather-icon icon="CopyIcon" size="14" />
        </b-button>
      </div>
    </b-card-text>
    <b-card-text class="text-center">
      <b-button size="sm" class="outline-primary-hover" variant="outline-primary" @click="$hideBlockUI()" style="width: 25%">Close</b-button>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { BButton, BFormInput, BCard, BCardTitle, BCardText } from 'bootstrap-vue';

export default {
  components: {
    BButton,
    BFormInput,
    BCard,
    BCardTitle,
    BCardText,
  },
  computed: {
    ...mapGetters(['getBlockUIVisible', 'getBlockUIMessage', 'getBlockUIButtonVisible', 'getBlockUIErrorVisible', 'getBlockUIErrorInfo', 'getBlockUIUnauthenticated']),
  },
  methods: {
    copyErrorCode() {
      const copyText = document.getElementsByName('error_code');
      if (copyText.length > 0) {
        copyText[0].select();
        document.execCommand('copy');
        this.$showToast({ title: 'Info', content: 'Error code copied.', type: 'info', position: 'right' });
        // $bvToast.show('Error code copied.');
        // $bvToast.show('example-toast')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.display-inlineblock {
  display: inline-block;
}

button.outline-secondary-hover:hover {
  color: white !important;
  background-color: #82868b !important;
}
button.outline-primary-hover:hover {
  background-color: #7367f0 !important;
  color: white !important;
}

button.copy-errorcode-button {
  padding: 0.25rem 0.4rem;
  margin-top: -5px;
  width: 30px;
  height: 30px;
}
</style>
