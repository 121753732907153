<template>
  <b-overlay :z-index="1100" :show="getBlockUIVisible" :variant="overlayVariant" :opacity="0.5" @click.native="blockUIClick" no-wrap :temp="getBlockUIErrorVisible">
    <template #overlay>
      <block-message v-if="!getBlockUIErrorVisible && !getBlockUIButtonVisible">></block-message>
      <block-message-button v-if="!getBlockUIErrorVisible && getBlockUIButtonVisible"></block-message-button>
      <block-error v-if="getBlockUIVisible && getBlockUIErrorVisible"></block-error>
    </template>
  </b-overlay>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import BlockMessage from './BlockUI/BlockMessage.vue';
import BlockMessageButton from './BlockUI/BlockMessageButton.vue';
import BlockError from './BlockUI/BlockError.vue';

import { BOverlay } from 'bootstrap-vue';

import useAppConfig from '@core/app-config/useAppConfig';

export default {
  name: 'blockui',
  components: {
    BlockMessage,
    BlockMessageButton,
    BlockError,

    BOverlay,
  },
  data() {
    return {
      error_code: '',
    };
  },
  mounted() {
    document.addEventListener('keydown', this.blockUIKeydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.blockUIKeydown);
  },
  computed: {
    ...mapGetters(['getBlockUIVisible', 'getBlockUIButtonVisible', 'getBlockUIErrorVisible']),
    overlayVariant() {
      let skin = useAppConfig().skin.value;
      if (skin === 'light' || skin === 'bordered' || skin === 'semi-dark') {
        return 'light';
      } else {
        return 'dark';
      }
    },
  },
  methods: {
    ...mapMutations(['showConfirmUI', 'hideConfirmUI']),
    blockUIKeydown(event) {
      if (this.getBlockUIVisible) {
        if (event.code == 'Escape') {
          // this.$hideBlockUI();
        }
      }
    },
    blockUIClick() {},
  },
};
</script>

<style lang="scss"></style>
