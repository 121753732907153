<template>
  <div class="text-center">
    <div :class="{ 'light-skin': !isDarkSkin, 'dark-skin': isDarkSkin }">
      {{ getBlockUIMessage }}
    </div>
    <div class="mt-1">
      <b-button size="sm" v-if="getBlockUIButtonVisible && !getBlockUIUnauthenticated" variant="danger" @click="$hideBlockUI()"> Ouch! </b-button>
      <b-button size="sm" v-if="getBlockUIButtonVisible && getBlockUIUnauthenticated" variant="danger" @click="reloadPage()"> Home </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

import useAppConfig from '@core/app-config/useAppConfig';

export default {
  components: {
    BButton,
  },
  computed: {
    ...mapGetters(['getBlockUIMessage', 'getBlockUIButtonVisible', 'getBlockUIUnauthenticated']),
    isDarkSkin() {
      let skin = useAppConfig().skin.value;
      if (skin === 'light' || skin === 'bordered' || skin === 'semi-dark') {
        return false;
      } else return true;
    },
  },
  methods: {
    reloadPage() {
      this.$hideBlockUI();
      window.location.href = '/';
    },
    historyBack() {
      window.history.back();
      this.$hideBlockUI();
    },
  },
};
</script>

<style lang="scss" scoped>
.light-skin {
  color: #000000;
  text-shadow: 1px 1px #ffffff;
}
.dark-skin {
  color: #ffffff;
  text-shadow: 1px 1px #000000;
}
</style>
