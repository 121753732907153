import { render, staticRenderFns } from "./BlockError.vue?vue&type=template&id=753d3c70&scoped=true&"
import script from "./BlockError.vue?vue&type=script&lang=js&"
export * from "./BlockError.vue?vue&type=script&lang=js&"
import style0 from "./BlockError.vue?vue&type=style&index=0&id=753d3c70&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "753d3c70",
  null
  
)

export default component.exports