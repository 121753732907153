<template>
  <div id="block-ui-container">
    <div :style="'color:' + messageColor">{{ getBlockUIMessage }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import useAppConfig from '@core/app-config/useAppConfig';

export default {
  computed: {
    ...mapGetters(['getBlockUIVisible', 'getBlockUIMessage', 'getBlockUIButtonVisible', 'getBlockUIErrorVisible', 'getBlockUIErrorInfo', 'getBlockUIUnauthenticated']),
    messageColor() {
      let skin = useAppConfig().skin.value;
      if (skin === 'light' || skin === 'bordered' || skin === 'semi-dark') {
        return '#000000';
      } else {
        return '#FFFFFF';
      }
    },
  },
};
</script>

<style></style>
